import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';
import ValidationErrorPlot from './ValidationErrorPlot';

const JobList = () => {
  const { project, branch, commit } = useParams();
  const [jobs, setJobs] = useState([]);
  const [differingVars, setDifferingVars] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});

  useEffect(() => {
    fetch(`${config.apiUrl}/${project}/${branch}/${commit}`)
      .then(response => response.json())
      .then(data => {
        setJobs(data.jobs);
        setDifferingVars(data.differing_vars);
        const initialFilters = Object.keys(data.differing_vars).reduce((acc, varName) => {
          acc[varName] = '';
          return acc;
        }, {});
        setSelectedFilters(initialFilters);
      });
  }, [project, branch, commit]);

  console.log(jobs);
  const filteredJobs = jobs.filter(job =>
    Object.keys(differingVars).every(varName =>
      selectedFilters[varName] === '' || job.differing_vars[varName] === selectedFilters[varName]
    )
  );

  return (
    <div>
      <h1>Job List for Commit {commit} in {branch} of {project}</h1>
      <div>
        {Object.keys(differingVars).map(varName => (
          <label key={varName}>
            {varName}:
            <select 
              value={selectedFilters[varName]} 
              onChange={e => setSelectedFilters({
                ...selectedFilters, 
                [varName]: e.target.value
              })}
            >
              <option value="">All</option>
              {differingVars[varName].map(value => (
                <option key={value} value={value}>{value}</option>
              ))}
            </select>
          </label>
        ))}
      </div>
      <ul>
        {filteredJobs.map(job => (
          <li key={job.name}>
            <a href={`/${project}/${branch}/${commit}/${job.id}`}>{job.name}</a>
          </li>
        ))}
      </ul>
      <ValidationErrorPlot />
    </div>
  );
};

export default JobList;
