import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';
import * as d3 from 'd3';
import { createPlot, hashStringToColor } from './Plot';

const ValidationErrorPlot = () => {
    const { project, branch, commit } = useParams();
    const [allValLogs, setAllValLogs] = useState(null);


    // if (!allValLogs) {
    //     return <div>Loading...</div>;
    // }

    useEffect(() => {
        fetch(`${config.apiUrl}/${project}/${branch}/${commit}/val_logs_files`)
            .then(response => response.json())
            .then(data => {
                console.log("data", data);
                setAllValLogs(data);
            });
    }, [project, branch, commit]);

    if (allValLogs) {
        const datasets = allValLogs.map(log => ({
            jobDir: log.job_id,
            data: log.data.map(entry => entry.val_err),
            steps: log.data.map(entry => entry.epoch),
            commit: log.commit
        }));

        // Clear existing plot
        d3.select("#val-error-plot").select("svg").remove();
        createPlot(datasets, "#val-error-plot", "Validation Error");

        // Add legend with checkboxes
        // Clear existing legend
        d3.select("#val-error-plot").select(".legend").remove();
        const legend = d3.select("#val-error-plot").append("div").attr("class", "legend");
        datasets.forEach(dataset => {
            const color = hashStringToColor(dataset.jobDir);
            const jobDir = dataset.jobDir;
            const commit = dataset.commit;
            const legendItem = legend.append("div").attr("class", "legend-item");
            legendItem.append("input")
                .attr("type", "checkbox")
                .attr("checked", true)
                .attr("id", `checkbox-${jobDir}`)
                .on("change", function() {
                    updatePlot();
                })
                .on("mouseover", function() {
                    d3.selectAll(`.line-${jobDir}`).attr("stroke-width", 3);
                    d3.selectAll(`.circle-${jobDir}`).attr("r", 6);
                })
                .on("mouseout", function() {
                    d3.selectAll(`.line-${jobDir}`).attr("stroke-width", 1.5);
                    d3.selectAll(`.circle-${jobDir}`).attr("r", 3);
                });

            legendItem.append("label")
                .attr("for", `checkbox-${jobDir}`)
                .style("color", color)
                .text(commit + '/' + jobDir)
                .on("mouseover", function() {
                    d3.selectAll(`.line-${jobDir}`).attr("stroke-width", 3);
                    d3.selectAll(`.circle-${jobDir}`).attr("r", 6);
                })
                .on("mouseout", function() {
                    d3.selectAll(`.line-${jobDir}`).attr("stroke-width", 1.5);
                    d3.selectAll(`.circle-${jobDir}`).attr("r", 3);
                });
        });

        function updatePlot() {
            const filteredDatasets = datasets.filter(dataset => {
                return d3.select(`#checkbox-${dataset.jobDir}`).property("checked");
            });

            d3.select("#val-error-plot").select("svg").remove();
            createPlot(filteredDatasets, "#val-error-plot", "Validation Error");
        }
    }

    return (
        <div>
            <h1>Validation Error Plot</h1>
            <div id="val-error-plot"></div>
        </div>
    );
};

export default ValidationErrorPlot;
